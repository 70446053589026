import { faLinkedin, faYoutubeSquare, faTwitterSquare, faFacebookSquare } from '@awesome.me/kit-065f90d5b1/icons/classic/brands'
import {  } from '@awesome.me/kit-065f90d5b1/icons/classic/regular'

const icons = { faLinkedin, faYoutubeSquare, faTwitterSquare, faFacebookSquare }

interface Item {
	useImageAsIcon?: boolean;
	faIconName?: string;
	iconImage?: { url: string };
	icon?: string;
}
export const iconNamesToIcons = function (items: unknown) {
	if (!Array.isArray(items)) return items;
	return items.map(function (item) {
		if (!item.useImageAsIcon)
			return {...item, icon: icons[item.faIconName as keyof typeof icons]}
		return { ...item, icon: item.iconImage.url }
	})
}
// export const iconNamesToIcons = function (items: unknown) {
// 	if (!Array.isArray(items)) return items;
// 	return items.map(function (item) {
// 		return {
// 			...item,
// 			icon: item.iconImage?.url || (typeof item.faIconName === 'string') ? icons[item.faIconName as keyof typeof icons] : null
// 		}
// 	})
// }



// export async function iconNamesToIcons(items: unknown, type: 'brands' | 'regular' = 'regular') {
// 	if (Array.isArray(items)) {
// 		const loadedIcons:Record<string,Item['icon']> = {};

// 		for (const item of items) {
// 			if (!item.useImageAsIcon && item.faIconName) {
// 				if (!loadedIcons[item.faIconName]) {
// 					try {
// 						if (type === 'regular') {
// 							const iconModule = await import(`@awesome.me/kit-065f90d5b1/icons/classic/regular/${item.faIconName}`);
// 							loadedIcons[item.faIconName] = iconModule.default;
// 						}
// 						else if (type === 'brands') {
// 							const iconModule = await import(`@awesome.me/kit-065f90d5b1/icons/classic/brands/${item.faIconName}`);
// 							loadedIcons[item.faIconName] = iconModule.default;
// 						}
// 					} catch (error) {
// 						console.error(`Failed to load icon: ${item.faIconName}`, error);
// 					}
// 				}
// 				item.icon = loadedIcons[item.faIconName];
// 			} else if (item.iconImage) {
// 				item.icon = item.iconImage.url;
// 			}
// 		}
// 	}

// 	return items;
// }
