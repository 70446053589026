export default {
    "name": "GlobalQuery",
    "kind": "HoudiniQuery",
    "hash": "8abb94ab08b4605d9f966de6817dcfd0fe0c2a875b2332a4d47fc46a2fab5c1c",

    "raw": `query GlobalQuery {
  settings(id: "9yE5DhufcfxHCUV0R34pK") {
    name
    legalName
    phoneCallCenter
    phones
    homePage {
      sys {
        id
      }
    }
    defaultPageTitle
    titleSuffix
    social: socialChannelsCollection(limit: 10) {
      total
      skip
      limit
      items {
        sys {
          id
        }
        name
        url
        useImageAsIcon
        iconImage {
          sys {
            id
          }
          url
          title
          size
          width
          height
          fileName
          description
          contentType
        }
        faIconName
      }
    }
    paymentMethods: paymentMethodsCollection(limit: 10) {
      total
      skip
      limit
      items {
        sys {
          id
        }
        name
        useImageAsIcon
        iconImage {
          sys {
            id
          }
          url
          title
          size
          width
          height
          fileName
          description
          contentType
        }
        faIconName
      }
    }
  }
  topNav: navigation(id: "4ZuDLFNcguVqIM3qjfa6PU") {
    name
    label
    navItems: navItemsCollection(limit: 30) {
      total
      skip
      limit
      items {
        name
        label
        page {
          name
          path
        }
        navItems: navItemsCollection(limit: 30) {
          total
          skip
          limit
          items {
            name
            label
            page {
              name
              path
            }
            iconName
          }
        }
      }
    }
  }
  serviceCategories: serviceCategoryCollection(limit: 30) {
    total
    skip
    limit
    items {
      sys {
        id
      }
      name
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "settings": {
                "type": "Settings",
                "keyRaw": "settings(id: \"9yE5DhufcfxHCUV0R34pK\")",
                "nullable": true,

                "selection": {
                    "fields": {
                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "nullable": true,
                            "visible": true
                        },

                        "legalName": {
                            "type": "String",
                            "keyRaw": "legalName",
                            "nullable": true,
                            "visible": true
                        },

                        "phoneCallCenter": {
                            "type": "String",
                            "keyRaw": "phoneCallCenter",
                            "nullable": true,
                            "visible": true
                        },

                        "phones": {
                            "type": "JSON",
                            "keyRaw": "phones",
                            "nullable": true,
                            "visible": true
                        },

                        "homePage": {
                            "type": "SiteMap",
                            "keyRaw": "homePage",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "sys": {
                                        "type": "Sys",
                                        "keyRaw": "sys",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "String",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "defaultPageTitle": {
                            "type": "String",
                            "keyRaw": "defaultPageTitle",
                            "nullable": true,
                            "visible": true
                        },

                        "titleSuffix": {
                            "type": "String",
                            "keyRaw": "titleSuffix",
                            "nullable": true,
                            "visible": true
                        },

                        "social": {
                            "type": "SettingsSocialChannelsCollection",
                            "keyRaw": "social(limit: 10)",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "total": {
                                        "type": "Int",
                                        "keyRaw": "total",
                                        "visible": true
                                    },

                                    "skip": {
                                        "type": "Int",
                                        "keyRaw": "skip",
                                        "visible": true
                                    },

                                    "limit": {
                                        "type": "Int",
                                        "keyRaw": "limit",
                                        "visible": true
                                    },

                                    "items": {
                                        "type": "SocialChannels",
                                        "keyRaw": "items",

                                        "selection": {
                                            "fields": {
                                                "sys": {
                                                    "type": "Sys",
                                                    "keyRaw": "sys",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "String",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "url": {
                                                    "type": "String",
                                                    "keyRaw": "url",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "useImageAsIcon": {
                                                    "type": "Boolean",
                                                    "keyRaw": "useImageAsIcon",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "iconImage": {
                                                    "type": "Asset",
                                                    "keyRaw": "iconImage",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "sys": {
                                                                "type": "Sys",
                                                                "keyRaw": "sys",

                                                                "selection": {
                                                                    "fields": {
                                                                        "id": {
                                                                            "type": "String",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "url": {
                                                                "type": "String",
                                                                "keyRaw": "url",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "title": {
                                                                "type": "String",
                                                                "keyRaw": "title",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "size": {
                                                                "type": "Int",
                                                                "keyRaw": "size",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "width": {
                                                                "type": "Int",
                                                                "keyRaw": "width",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "height": {
                                                                "type": "Int",
                                                                "keyRaw": "height",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "fileName": {
                                                                "type": "String",
                                                                "keyRaw": "fileName",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "description": {
                                                                "type": "String",
                                                                "keyRaw": "description",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "contentType": {
                                                                "type": "String",
                                                                "keyRaw": "contentType",
                                                                "nullable": true,
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "faIconName": {
                                                    "type": "String",
                                                    "keyRaw": "faIconName",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "paymentMethods": {
                            "type": "SettingsPaymentMethodsCollection",
                            "keyRaw": "paymentMethods(limit: 10)",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "total": {
                                        "type": "Int",
                                        "keyRaw": "total",
                                        "visible": true
                                    },

                                    "skip": {
                                        "type": "Int",
                                        "keyRaw": "skip",
                                        "visible": true
                                    },

                                    "limit": {
                                        "type": "Int",
                                        "keyRaw": "limit",
                                        "visible": true
                                    },

                                    "items": {
                                        "type": "PaymentTypes",
                                        "keyRaw": "items",

                                        "selection": {
                                            "fields": {
                                                "sys": {
                                                    "type": "Sys",
                                                    "keyRaw": "sys",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "String",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "useImageAsIcon": {
                                                    "type": "Boolean",
                                                    "keyRaw": "useImageAsIcon",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "iconImage": {
                                                    "type": "Asset",
                                                    "keyRaw": "iconImage",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "sys": {
                                                                "type": "Sys",
                                                                "keyRaw": "sys",

                                                                "selection": {
                                                                    "fields": {
                                                                        "id": {
                                                                            "type": "String",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "url": {
                                                                "type": "String",
                                                                "keyRaw": "url",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "title": {
                                                                "type": "String",
                                                                "keyRaw": "title",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "size": {
                                                                "type": "Int",
                                                                "keyRaw": "size",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "width": {
                                                                "type": "Int",
                                                                "keyRaw": "width",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "height": {
                                                                "type": "Int",
                                                                "keyRaw": "height",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "fileName": {
                                                                "type": "String",
                                                                "keyRaw": "fileName",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "description": {
                                                                "type": "String",
                                                                "keyRaw": "description",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "contentType": {
                                                                "type": "String",
                                                                "keyRaw": "contentType",
                                                                "nullable": true,
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "faIconName": {
                                                    "type": "String",
                                                    "keyRaw": "faIconName",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "topNav": {
                "type": "Navigation",
                "keyRaw": "topNav(id: \"4ZuDLFNcguVqIM3qjfa6PU\")",
                "nullable": true,

                "selection": {
                    "fields": {
                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "nullable": true,
                            "visible": true
                        },

                        "label": {
                            "type": "String",
                            "keyRaw": "label",
                            "nullable": true,
                            "visible": true
                        },

                        "navItems": {
                            "type": "NavigationNavItemsCollection",
                            "keyRaw": "navItems(limit: 30)",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "total": {
                                        "type": "Int",
                                        "keyRaw": "total",
                                        "visible": true
                                    },

                                    "skip": {
                                        "type": "Int",
                                        "keyRaw": "skip",
                                        "visible": true
                                    },

                                    "limit": {
                                        "type": "Int",
                                        "keyRaw": "limit",
                                        "visible": true
                                    },

                                    "items": {
                                        "type": "NavItem",
                                        "keyRaw": "items",

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "label": {
                                                    "type": "String",
                                                    "keyRaw": "label",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "page": {
                                                    "type": "SiteMap",
                                                    "keyRaw": "page",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "path": {
                                                                "type": "String",
                                                                "keyRaw": "path",
                                                                "nullable": true,
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "navItems": {
                                                    "type": "NavItemNavItemsCollection",
                                                    "keyRaw": "navItems(limit: 30)",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "total": {
                                                                "type": "Int",
                                                                "keyRaw": "total",
                                                                "visible": true
                                                            },

                                                            "skip": {
                                                                "type": "Int",
                                                                "keyRaw": "skip",
                                                                "visible": true
                                                            },

                                                            "limit": {
                                                                "type": "Int",
                                                                "keyRaw": "limit",
                                                                "visible": true
                                                            },

                                                            "items": {
                                                                "type": "NavItem",
                                                                "keyRaw": "items",

                                                                "selection": {
                                                                    "fields": {
                                                                        "name": {
                                                                            "type": "String",
                                                                            "keyRaw": "name",
                                                                            "nullable": true,
                                                                            "visible": true
                                                                        },

                                                                        "label": {
                                                                            "type": "String",
                                                                            "keyRaw": "label",
                                                                            "nullable": true,
                                                                            "visible": true
                                                                        },

                                                                        "page": {
                                                                            "type": "SiteMap",
                                                                            "keyRaw": "page",
                                                                            "nullable": true,

                                                                            "selection": {
                                                                                "fields": {
                                                                                    "name": {
                                                                                        "type": "String",
                                                                                        "keyRaw": "name",
                                                                                        "nullable": true,
                                                                                        "visible": true
                                                                                    },

                                                                                    "path": {
                                                                                        "type": "String",
                                                                                        "keyRaw": "path",
                                                                                        "nullable": true,
                                                                                        "visible": true
                                                                                    }
                                                                                }
                                                                            },

                                                                            "visible": true
                                                                        },

                                                                        "iconName": {
                                                                            "type": "String",
                                                                            "keyRaw": "iconName",
                                                                            "nullable": true,
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "serviceCategories": {
                "type": "ServiceCategoryCollection",
                "keyRaw": "serviceCategories(limit: 30)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "total": {
                            "type": "Int",
                            "keyRaw": "total",
                            "visible": true
                        },

                        "skip": {
                            "type": "Int",
                            "keyRaw": "skip",
                            "visible": true
                        },

                        "limit": {
                            "type": "Int",
                            "keyRaw": "limit",
                            "visible": true
                        },

                        "items": {
                            "type": "ServiceCategory",
                            "keyRaw": "items",

                            "selection": {
                                "fields": {
                                    "sys": {
                                        "type": "Sys",
                                        "keyRaw": "sys",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "String",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "CacheOrNetwork",
    "partial": false
};

"HoudiniHash=631fb8b151590033146b1fbd3940d73d9f394e9ba2301760217d3e794e9dea8a";