import type { AfterLoadEvent } from './$houdini';
import { iconNamesToIcons, telToHref } from '$lib/functions/utils';

export async function _houdini_afterLoad({ data }: AfterLoadEvent) {
	const globalInfo = Object.assign(data.GlobalQuery, {
		settings: {
			...data.GlobalQuery?.settings,
			social: {
				...data.GlobalQuery?.settings?.social,
				items: iconNamesToIcons(data.GlobalQuery?.settings?.social?.items)
			},
			paymentMethods: {
				...data.GlobalQuery?.settings?.paymentMethods,
				items: iconNamesToIcons(data.GlobalQuery?.settings?.paymentMethods?.items)
			},
			phoneCallCenterHref: telToHref(data.GlobalQuery?.settings?.phoneCallCenter)
		}
	});

	return {
		globalInfo
	};
}
